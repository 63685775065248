.fullSquareBox {
  width: 100vh;
  height: 100vh;
  margin: 0 auto;
}
@media all and (orientation: portrait) {
  .fullSquareBox {
      width: 100vw;
      height: 100vw;
  }
}
