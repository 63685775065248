body {
  background-color: #e4d8c7;
  background-color: #eceae8;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  filter: brightness(0.75);
}

.gamePiece {
  transition: cx 0.5s, cy 0.5s;
}

/* modals & menus */

.modal, .menu {
  background-color: #3f3e3d; 
  color: #eceae8; 
  padding: 0.25rem;
}

.modal-wrapper {
  position: absolute; 
}
.modal {
  position: absolute;
  bottom: 20px;
  left: 0;
  transform: translate(-50%, 0);
}
.modal.modal-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.modal-button {
  padding: 0.75rem 1.25rem;
  background-color: #3f3e3d; 
  color: #eceae8; 
  border: 0;
  cursor: pointer;
  white-space: nowrap;
}
.modal-button:hover {
  cursor: pointer;
  background-color: #111010;
}

.menu .menu-button {
  padding: 1rem;
  font-size: 1.5rem;
}

/* Nav menu */

.nav-menu {
  background-color: #252424;
}

.nav-menu .menu-button {
  padding: 0 1rem;
  color: #eceae8; 
  /* font-size: 1.125rem; */
}